<template>
  <div>
    <Navbar />
    
    <div class="wrap">
      <div class="row content content-inner">
        <Sidebar />
        <router-view />
      </div>
    </div>
    <footer class="wrap-footer">
        <div class="wrap-footer-row copyback">
            <div class="footer-copyright">
                <div class="footer-copy">
                    <span class="bottom-footer-text">©©© 2016-{{currentYear}}   <a href="https://credits.com" target="_blank" style="color:white">Credits.com</a>   All Rights Reserved.</span>
                </div>
            </div>
        </div>
    </footer>
  </div>
</template>
<script>
import Sidebar from "@/components/app/sidebar";
import Navbar from "@/components/app/navbar";
export default {
  name: "account-layout",
  data(){
    return{};
  },
  computed:{
     currentYear: function(){
       return new Date().getFullYear();
     }
  },
  components: {
    Sidebar,
    Navbar
  }
};
</script>