<template>
  <div class="popup-bg">
    <div class="popup-alert-wrap">
      <p class="big-awr">Before you get started</p>
      <p class="small-awr">
        Credits Wallet does not collect or store
        <br />any information about you.
      </p>
      <hr class="hr-awr" />
      <p class="row-awr">
        <input class="inp-awr" type="checkbox" id="CheckAllow1" v-model="checkBox1" />
        <span class="mid-awr">
        I accept that my funds are
        stored on this account and it is impossible to recover account access if it is lost.
      </span>
      </p>
      

      <p class="row-awr">
        <input class="inp-awr" type="checkbox" id="CheckAllow2" v-model="checkBox2" />
        <span
          class="mid-awr"
        >I accept that the transfer of access keys or an access file to second parties can lead to theft of funds available on the account.</span>
      </p>
      <button
        class="bold-link bttn disabled-bttn-awr mb-20"
        id="RegisterAllow"
        :disabled="!checkBox1 && !checkBox2"
        @click.prevent="validateCheckBox"
      >Send</button>
      <p class="re-awr">
        <a class="link_grey" href="http://wallet.credits.com">I do not approve</a>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "popup",
  methods: {
    validateCheckBox: function(e) {
        this.$emit("allowSend");
    }
  },
  data: function() {
    return {
      checkBox1: false,
      checkBox2: false
    };
  }
};
</script>