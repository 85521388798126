<template>
  <div class="left-bar">
    <div class="account_field mb-50">
      <div class="hiddenly-ac hidden-acc">
        <div class="left-bar-title">
          <div class="font_w_600">{{$t("Acc_Desc_1")}}</div>
          <v-popover offset="16">  <!--:disabled="!isEnabled"-->
            <button type="button"
                    class="resetBtn"
                    v-clipboard:copy="publicKey"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError">
              <img src="/img/icon/copy.png" id="CopyWalletKeyButton" alt="" />
            </button>
            <template slot="popover">
              <span>Public key was copy!</span>
            </template>
          </v-popover>

          <a @click.prevent="qrcodeHandler" id="qrBtn">
            <img id="QrKeyButton" src="/img/icon/Qr.png" alt />
          </a>
        </div>
        <div class="breaked mb-25" id="WalletKey">{{publicKey}}</div>
        <div class="left-bar-title">
          <div class="font_w_600">{{$t("Acc_Desc_2")}}</div>
          <a class="d-flex-center" @click.prevent="updateWalet">
            <img id="UpdateBalanceButton" src="/img/icon/Reload.png" alt />
          </a>
        </div>
        <div class="breaked" id="Balance">
          <table class="balance_table">
            <tr>
              <td class="one">
                <img class="img-icon-crc" src="/img/icon/favicon.ico" />
                <span>Credits (CS)</span>
              </td>
              <td class="two">
                <div class="local-el" id="Balance_number" ref="balance">{{CSCurrency}}</div>
              </td>
            </tr>
            <!--<tr>
        <td class="one">
          <span>Del Granted:</span>
        </td>
        <td class="two">
          <div class="local-el" id="Del_number" ref="del">{{delegatedOut}}</div>
        </td>
      </tr>
      <tr>
        <td class="one">
          <span>Del Received:</span>
        </td>
        <td class="two">
          <div class="local-el" id="Del_number" ref="del">{{delegatedIn}}</div>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <hr>
        </td>
      </tr>
      <tr>
        <td class="one">
          <hr>
          <span>Spendable:</span>
        </td>
        <td class="two">
          <div class="local-el" id="Del_number" ref="del">{{CSCurrency}}</div>
        </td>
      </tr>
      <tr>
        <td class="one">
          <span>Stakeable:</span>
        </td>
        <td class="two">
          <div class="local-el" id="Del_number" ref="del">{{CSCurrency + delegatedIn}}</div>
        </td>
      </tr>-->
          </table>
        </div>
        <div class="slide-popup mb-25" id="CoinMarketCap"></div>
        <div class="font_w_600">{{$t("Acc_Desc_14")}}</div>
        <div class="breaked BalanceToken" id="BalanceToken">
          <table class="balance_table">
            <tr v-for="(item, index) in tokenList" :key="index">
              <td v-if="item.publicKey===''|| item.publicKey===null">
                <span>{{item.publicKey}}</span>
              </td>
              <td v-else>
                <a target="_blank"
                   :href="`https://monitor.credits.com/CreditsNetwork/token/${item.publicKey}`">
                  {{item.alias}}
                </a>
              </td>
              <td>{{item.amount}}</td>
            </tr>
          </table>
        </div>
        <div class="button-balanceToken" id="ShowTokenListButton" @click="btnShowBalanceClick">
          <div class="arrow-top"></div>
        </div>
        <div class="breaked mb-20"></div>
        <div>
          <router-link :to="`/${$i18n.locale}/wallet/perform-transaction`" id="PerformTransactionButton" class="link">
            <img src="/img/icon/PerformTransaction.png" alt />
            {{$t("Acc_Desc_13")}}
          </router-link>
        </div>
        <div>
          <a class="link"
             :href="`https://monitor.credits.com/CreditsNetwork/account/${publicKey}`"
             target="_blank"
             id="MonitorHistory">
            <img src="/img/icon/monitorDertailed.png" alt />
            {{$t("Acc_Desc_3")}}
          </a>
        </div>
        <!--<div>
    <a id="SmartContractButton" class="link">
      <img src="../Content/image/icon/SmarContract.png" alt />
      Smart contracts
    </a>
  </div>
  <div>
    <a id="CreateSmartButton" class="link">
      <img src="../Content/image/icon/CreateSmart.png" alt />
      Create smart contract
    </a>
  </div>-->
        <div>
          <router-link id="ContractCreate" class="link" :to="`/${$i18n.locale}/wallet/create-smart`">
            <img src="/img/icon/CreateSmart.png" alt />
            {{$t("Acc_Desc_19")}}
          </router-link>
        </div>
        <div>
          <router-link id="CreateTokenButton" class="link" :to="`/${$i18n.locale}/wallet/create-token`">
            <img src="/img/icon/CreateToken.png" alt />
            {{$t("Acc_Desc_12")}}
          </router-link>
        </div>
        <div>
          <router-link id="CreateDelegation" class="link" :to="`/${$i18n.locale}/wallet/delegation`">
            <img src="/img/icon/PerformTransaction.png" alt />
            {{$t("Acc_Desc_17")}}
          </router-link>
        </div>
        <div>
          <router-link id="SmartContractsButton" class="link" :to="`/${$i18n.locale}/wallet/smart-contracts`">
            <img src="/img/icon/SmarContract.png" alt />
            Smart Contracts
          </router-link>
        </div>

        <!--div
    <router-link id="DelegationsDetailed" class="link" :to="`/${$i18n.locale}/wallet/delegation-details`">
      <img src="/img/icon/PerformTransaction.png" alt />
      {{$t("Acc_Desc_18")}}
    </router-link>
  </!--div-->
        <!--div>
  <router-link id="ContractCreate" class="link" :to="`/${$i18n.locale}/wallet/create-smart`">
    <img src="/img/icon/CreateSmart.png" alt />
        {{$t("Acc_Desc_19")}}
        </router-link>
        </!--div>
        <div>
          <router-link id="ContractExecute" class="link" :to="`/${$i18n.locale}/wallet/delegation`">
            <img src="/img/icon/SmarContract.png" alt />
            {{$t("Acc_Desc_20")}}
          </router-link>
        </div>-->
      </div>
    </div>
    <div class="faq_field">
      <h2 class="title main-text">{{$t("FAQ_h2")}}</h2>
      <div class="main-text hiddenly-ac hidden-faq">
        <a
          class="link"
          target="_blank"
          href="https://developers.credits.com/en/Articles/a_Accounts_and_Keyfiles"
        >{{$t("FAQ_item_1")}}</a>
        <a
          class="link"
          target="_blank"
          href="https://developers.credits.com/en/Articles/a_How_to_use_Web_Wallet#Create%20a%20new%20account"
        >{{$t("FAQ_item_2")}}</a>
        <a
          class="link"
          target="_blank"
          href="https://developers.credits.com/en/Articles/a_How_to_use_Web_Wallet#Sign%20in%20with%20a%20previously%20created%20user%20account"
        >{{$t("FAQ_item_3")}}</a>
        <a
          class="link"
          target="_blank"
          href="https://developers.credits.com/en/Articles/a_Accounts_and_Keyfiles#Public%20and%20private%20keys"
        >{{$t("FAQ_item_4")}}</a>
        <a
          class="link"
          target="_blank"
          href="https://developers.credits.com/en/Articles/a_Accounts_and_Keyfiles#Keyfile,%20what%20it%20is%20used%20for,%20how%20to%20create%20it"
        >{{$t("FAQ_item_5")}}</a>
        <a
          class="link"
          target="_blank"
          href="https://developers.credits.com/en/Articles/a_How_to_use_Web_Wallet#Operations%20performed%20inside%20user%20wallet"
        >{{$t("FAQ_item_6")}}</a>
      </div>
    </div>
    <div class="loader-wrap">
      <div id="qrModal" class="modal">
        <div class="modal-content">
          <span class="close">&times;</span>

          <p>
            <canvas width="128" height="128" style="display: none; margin:auto"></canvas>
            <canvas style="margin:auto; display:block" id="qrcodeImg"></canvas>
          </p>
        </div>
      </div>
    </div>

    <div class="micropopup">
      <div class="micro-title-row">
        <span @click="closePopupCopy">X</span>
      </div>
      <div class="micro-text-row">{{$t("clip")}}</div>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import {mapState} from "vuex";
  import QRCode from "qrcode";

  export default {
    name: "wallet-sidebar",
    props: ["toUpdate"],
    methods: {
      updateWalet: function () {
        this.$loading(false);
        this.displayedPreloader = true;
        let postData = {
          MethodApi: "GetBalance",
          PublicKey: this.publicKey,
          NetworkAlias: this.networkAlias
        };
        let postContent = JSON.stringify(postData);
        console.log(postContent);
        new Promise((resolver, reject) => {
          this.$loading(true);
          let httpInstance = axios
            .post("../../api/wallet/GetBalanceData", postContent, {
              headers: {
                "Content-Type": "application/json"
              }
            })
            .then(x => {
              this.balanceList = [];
              this.tokenList = [];
              this.CSCurrency = x.data.balance;
              //console.log("Line1");
              this.balanceList.push({ "publicKey": "", "name": "CS", "alias": "CS", "amount": x.data.balance });
              this.$store.commit("SET_balanceList", this.balanceList);
              //console.log("Line2");
              this.delegatedIn = x.data.delegatedIn;
              this.delegatedOut = x.data.delegatedOut;
              for (let index = 0; index < x.data.tokens.length; index++) {
                const item = x.data.tokens[index];
                this.tokenList.push(x.data.tokens[index]);
                this.balanceList.push(x.data.tokens[index]);
              }
              //console.log(this.balanceList);
              this.$loading(false);
            })
            .catch(x => {
              console.log("Error load data");
              console.log(x);
              this.$loading(false);
            });
        });

        console.log(" - check balance list");
        // console.log(this.balanceList);
      },
      clipboardHandler: function (e) {
        alert("clipBoard");
        console.log(e);
        let el = document.getElementById("WalletKey");
        console.log(el.textContent);
      },
      btnShowBalanceClick: function (e) {
        let el = document.getElementById("BalanceToken");

        if (el.style.maxHeight === "200px") {
          el.style.maxHeight = "unset";
        } else {
          el.style.maxHeight = "200px";
        }
      },
      qrcodeHandler: function () {
        let pubKey = this.publicKey;
        let canvas = document.getElementById("qrcodeImg");
        console.log(canvas);
        QRCode.toCanvas(canvas, pubKey, function (error) {
          if (error) {
            console.log(error);
          } else {
            console.log("qrcode success!");
          }
        });

        var modal = document.getElementById("qrModal");
        var btn = document.getElementById("qrBtn");
        var span = document.getElementsByClassName("close")[0];
        btn.onclick = function () {
          modal.style.display = "block";
        };
        span.onclick = function () {
          modal.style.display = "none";
        };
        window.onclick = function (event) {
          if (event.target == modal) {
            modal.style.display = "none";
          }
        };
      },
      closePopupCopy: function (e) {
        console.log(e.target);
        let tooltip = document.getElementsByClassName("micropopup")[0];
        console.log(tooltip);
        tooltip.style.opacity = 0;
      },
      onCopy: function (e) {
        let tooltip = document.getElementsByClassName("micropopup")[0];
        console.log(tooltip);
        tooltip.style.opacity = 1;

      },
      onError: function (e) {
        alert("Failed to copy texts");
      }
    },
    components: {},
    data() {
      return {
        balanceList: [],
        tokenList: [],
        CSCurrency: Object,
        monitorExternalLink: "",
        displayedPreloader: false
      };
    },
    created() {
      if (this.publicKey === "" || this.privateKey === "") {
        this.$router.push("/");
      }
      this.monitorExternalLink = ``;
    },
    mounted() {
      console.log("walet-sidebar");
      this.updateWalet();
    },
    watch: {
      toUpdate: {
        handler: function (value) {
          if (value) {
            this.updateWalet();
            this.$emit("returnUpdateCallback");
          }
        }
      }
    },
    computed: {
      ...mapState({
        publicKey: s => s.publicKey,
        privateKey: s => s.privateKey,
        networkAlias: s => s.networkAlias
      })
    }
  };
</script>

<style scoped>
  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
  }

  .modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 40%;
  }

  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .resetBtn {
    padding: 5px;
    margin: 0;
    line-height: 0;
    height: auto;
    background: transparent;
    border: none;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  @media (max-width: 768px) {
    .modal-content {
      width: 70%;
    }
  }

  @media (max-width: 316px) {
    .modal-content {
      width: 90%;
    }
  }
</style>
