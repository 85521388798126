<template>
  <div class="left-bar">
    <div class="faq_field">
      <h2 class="title main-text">{{$t("FAQ_h2")}}</h2>
      <div class="main-text hiddenly-ac hidden-faq">
        <a
          class="link"
          target="_blank"
          href="https://developers.credits.com/en/Articles/a_Accounts_and_Keyfiles"
        >{{$t("FAQ_item_1")}}</a>
        <a
          class="link"
          target="_blank"
          href="https://developers.credits.com/en/Articles/a_How_to_use_Web_Wallet#Create%20a%20new%20account"
        >{{$t("FAQ_item_2")}}</a>
        <a
          class="link"
          target="_blank"
          href="https://developers.credits.com/en/Articles/a_How_to_use_Web_Wallet#Sign%20in%20with%20a%20previously%20created%20user%20account"
        >{{$t("FAQ_item_3")}}</a>
        <a
          class="link"
          target="_blank"
          href="https://developers.credits.com/en/Articles/a_Accounts_and_Keyfiles#Public%20and%20private%20keys"
        >{{$t("FAQ_item_4")}}</a>
        <a
          class="link"
          target="_blank"
          href="https://developers.credits.com/en/Articles/a_Accounts_and_Keyfiles#Keyfile,%20what%20it%20is%20used%20for,%20how%20to%20create%20it"
        >{{$t("FAQ_item_5")}}</a>
        <a
          class="link"
          target="_blank"
          href="https://developers.credits.com/en/Articles/a_How_to_use_Web_Wallet#Operations%20performed%20inside%20user%20wallet"
        >{{$t("FAQ_item_6")}}</a>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  
}
</script>