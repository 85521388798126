<template>
  <div id="Work">
    <div class="bg-block text-center">
      <div class="content_main centered_column">
        <h2 class="main">{{$t("index_title")}}</h2>
        <div class="mb-50">
          <p class="main">{{$t("index_Desc_1")}}</p>
          <p class="main">{{$t("index_Desc_2")}}</p>
        </div>
        <div class="row w-650 around align-bottom mobile-center top_block">
          <div>
            <div class="margin_top_20">
              <router-link
               :to="`/${$i18n.locale}/register`"
                class="bold-link bttn2 whiter-c"
                v-on:click="displayPopup"
              >{{$t("index_button_1")}}</router-link>
            </div>
          </div>
          <div>
            <div class="margin_top_20">
              <router-link :to="`/${$i18n.locale}/login`" class="bold-link bttn whiter-w">{{$t("index_button_2")}}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content_main pb-20">
      <h2 class="title text-center">{{$t("index_h2_2")}}</h2>
      <div class="row around mobile-column mobile-center">
        <div class="feature">
          <img class="feature__img" src="/img/index/main-01-01.svg" />
          <div class="feature__title">{{$t("index_Desc_3")}}</div>
        </div>
        <div class="feature">
          <img class="feature__img" src="/img/index/main-02-01.svg" />
          <div class="feature__title">{{$t("index_Desc_4")}}</div>
        </div>
        <div class="feature">
          <img class="feature__img" src="/img/index/main-02-01.svg" />
          <div class="feature__title">{{$t("index_Desc_5")}}</div>
        </div>
        <div class="feature">
          <img class="feature__img" src="/img/index/main-03-01.svg" />
          <div class="feature__title">{{$t("index_Desc_6")}}</div>
        </div>
      </div>
    </div>
    <div class="bg-grey pt-40 pb-40" style="margin-bottom: -200px;">
      <div class="content_main">
        <h2 class="title text-center mb-20">{{$t("index_h2_3")}}</h2>
        <div class="mx-auto">
          <p
            class="main pb-20"
          >{{$t("index_Desc_7")}}</p>
          <p class="main pb-20">
           {{$t("index_Desc_8")}}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
// @ is an alias to /src
import Popup from "@/components/app/popup";
export default {
  methods: {
    displayPopup: function(e) {
      console.log(e);
    }
  },
  components: {
    Popup
  },
  created() {
  },
  mounted() {
    this.$loading(false);
    alert("Please check that you are visiting the correct URL  https://wallet.credits.com");
  }
};
</script>
