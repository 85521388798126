<template>
  <div id="app">
    <component :is="layout">
      <keep-alive>
        <router-view />
      </keep-alive>
    </component>
    <div id="animationCookie" class="displayFlex" :style="{display:cookiesDisplayed}">
      <div id="animationContentCookie">
        <div class="textCookie">
          This site uses cookies in order to improve your user experience and to provide content tailored specifically to your interests. Detailed information on the use of cookies on this website is provided in our
          <a
            href="/en/Home/Privacy"
            class="newbutnc CookieBarClose"
          >Privacy Policy</a>. By using this website, you consent to the use of cookies.
          You can always deactivate cookies in commonly used browsers.
        </div>
        <div class="knopkiCookie">
          <a @click="cookieHandler" class="btn newbutnc CookieBarClose">Ok</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MainLayout from "@/layouts/MainLayout";
import AccountLayout from "@/layouts/AccountLayout";
import WalletLayout from "@/layouts/WalletLayout";
import { mapState } from "vuex";
export default {
  data() {
    return {
      cookiesDisplayed: "block"
    };
  },
  methods: {
    cookieHandler: function() {
      this.cookiesDisplayed = "none";
      document.cookie = "cookiesDisplayed=false";
    }
  },
  mounted() {
    let _cookiesDisplayed = document.cookie.split(";").find(function(item) {
     console.log(item);
      return item === "cookiesDisplayed=false";
    });
    console.log(_cookiesDisplayed);
    if(_cookiesDisplayed){
      this.cookiesDisplayed = "none";
    }
  },
  computed: {
    layout() {
      console.log(this.$route.meta.layout);
      return this.$route.meta.layout + "-layout";
    },
    ...mapState({})
  },
  components: {
    MainLayout,
    AccountLayout,
    WalletLayout
  }
};
</script>
<style lang="css">
</style>
