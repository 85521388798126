<template>
  <div class="top-bar__el">
    <select class="select" @change="networkSelectorHandler(networkAlias)" v-model="networkAlias">
      <!-- <option value="CreditsNetwork" :selected="true">CreditsNetwork</option>
      <option value="Testnet">Testnet</option>-->
      <option :value="item.alias" v-for="(item,idx) in networks" :key="idx">{{item.name}}</option>
    </select>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "NetworkSelector",
  data() {
    return {
      networkAlias: "MainNet",
      networks: [
        {
          alias: "MainNet",
          name: "CreditsMainNetwork"
        },
        {
          alias: "Testnet",
          name: "Testnet"
        }
        //,
        //{
        //  alias: "Local",
        //  name: "Local"
        //}
      ]
    };
  },
  methods: {
    networkSelectorHandler: function(alias) {
      console.log(alias);
      this.$store.commit("SET_network", alias);
      this.$store.commit("SET_privateKey", "");
      this.$store.commit("SET_publicKey", "");
      this.$router.push("/");
    }
  },
  computed: {
    ...mapState({
      _networkAlias: state => state.networkAlias
    })
  },
  created() {
    this.networkAlias = this._networkAlias;
    if (this.networkAlias === "") {
      this.networkAlias = "MainNet";
    }
  }
};
</script>
