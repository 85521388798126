<template>
  <div>
    <Navbar />
    <div class="wrap">
      <div class="row content content-inner">
        <WalletSidebar v-bind:publicKey="publicKey" v-bind:toUpdate="preUpdate" @returnUpdateCallback="preUpdate = false" />
        <router-view @performTransactionUpdate="performTransactionHandler" />
      </div>
    </div>
    <footer class="wrap-footer">
        <div class="wrap-footer-row copyback">
            <div class="footer-copyright">
                <div class="footer-copy">
                    <span class="bottom-footer-text">©©© 2016-{{currentYear}}   <a href="https://credits.com" target="_blank" style="color:white">Credits.com</a>   All Rights Reserved.</span>
                </div>
            </div>
        </div>
    </footer>
  </div>
</template>
<script>
  import WalletSidebar from "@/components/app/walletSidebar";
  import Navbar from "@/components/app/navbar";

  export default {
  name: "wallet-layout",
  components: {
    WalletSidebar,
    Navbar
  },
  data() {
    return {
      publicKey: localStorage.getItem("pubKey"),
      preUpdate: false,
    };
  },
  methods: {
    performTransactionHandler: function() {
      this.preUpdate = true;
    }
  },
  computed:{
     currentYear: function(){
       return new Date().getFullYear();
     }
  },
  created() {
    //console.log("was created wallet layout!");
    //  console.log(this.$route.params.pk);
  }
};
</script>
