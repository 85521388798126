import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home.vue'
import i18n from './i18n'

Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [{
            path: "/",
            redirect: `/${i18n.locale}`
        },
        {
            path: "/:lang",
            component: {
                render(c) { return c("router-view") }
            },
            children: [{

                    path: '/',
                    name: 'home',
                    meta: { layout: 'main' },
                    component: Home
                },

                {
                    path: `register`,
                    name: 'register',
                    meta: { layout: 'account' },
                    component: () =>
                        import ('./views/Register.vue')
                },
                {
                    path: `login`,
                    name: 'login',
                    meta: { layout: 'account' },
                    component: () =>
                        import ('./views/Login.vue')
                },
                {
                    path: `wallet/perform-transaction`,
                    name: 'perform-transaction',
                    meta: { layout: 'wallet' },
                    component: () =>
                        import ('./views/PerformTransaction.vue')
                },
                {
                    path: `wallet/perform-transaction-result`,
                    name: 'perform-transaction-result',
                    meta: { layout: 'wallet' },
                    component: () =>
                        import ('./views/PerformTransactionResult.vue')
                },
                {
                    path: `wallet/success-transaction`,
                    name: 'completedTransaction',
                    meta: { layout: 'wallet' },
                    component: () =>
                        import ('./views/SuccessTrans.vue')
                },
                {
                    path: `wallet/create-smart`,
                    name: 'create-smart',
                    meta: { layout: 'wallet' },
                    component: () =>
                        import ('./views/CreateSmart.vue')
                },
                {
                    path: `wallet/create-token`,
                    name: 'create-token',
                    meta: { layout: 'wallet' },
                    component: () =>
                        import ('./views/CreateToken.vue')
                },
                {
                    path: `wallet/create-smart-result`,
                    name: 'create-smart-result',
                    meta: { layout: 'wallet' },
                    component: () =>
                        import ('./views/CreateSmartResult.vue')
                },
              {
                path: `wallet/delegation`,
                name: "delegation",
                meta: { layout: "wallet" },
                component: () => import("./views/Delegation.vue")
              },
              {
                path: `wallet/delegation-details`,
                name: "delegation-details",
                meta: { layout: "wallet" },
                component: () => import("./views/DelegationDetails.vue")
              },
              {
                path: `wallet/delegation-result`,
                name: "delegation-result",
                meta: { layout: "wallet" },
                component: () => import("./views/DelegationResult.vue")
              },
              {
                path: `wallet/smart-contracts`,
                name: "smart-contracts",
                meta: { layout: "wallet" },
                component: () => import("./views/SmartContracts.vue")
              }
            ]
        }
    ]
});

router.beforeResolve((to, from, next) => {
    if (to.name) {
        NProgress.start();
    }
    next();
});
router.afterEach((to, from) => {
    NProgress.done();
})
export default router;
