import Vue from 'vue';
import { store } from './store'
import App from './App.vue'
import Vuelidate from 'vuelidate';
import router from './router';
import loadScript from "vue-plugin-load-script";
import VueClipboard from 'vue-clipboard2';
import VueLoading from 'vuejs-loading-plugin';
import VueTheMask from 'vue-the-mask'
import Tooltip from "v-tooltip";
import i18n from './i18n'
VueClipboard.config.autoSetContainer = true;

Vue.use(Vuelidate);
Vue.use(loadScript);
Vue.use(VueClipboard);
Vue.use(VueLoading, {
  loading: false,
  dark: true,
  customLoader: null,
  background: null,
  classes: null
});

Vue.use(Tooltip);
Vue.config.productionTip = false

Vue.use(VueTheMask);

router.beforeEach((to, from, next) => {
    let language = to.params.lang;
    if (!language) {
        language = "en";
    }
    i18n.locale = language;
    next();
})

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
