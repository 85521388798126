<template>
  <div class="langBox">
    <div>
      <div>
        <!-- <div>{{currentLang}}</div> -->
        <div class>
          <select
            @change="selectLangHandler(currentLang)"
            v-model="currentLang"
            class="select-lang"
          >
            <option v-for="(itm,idx) in langs" :key="idx">{{itm}}</option>
          </select>
        </div>
      </div>
      <div class></div>
    </div>
  </div>
</template>
<script>
import i18n from "@/i18n";
import { mapState } from "vuex";
export default {
  data() {
    return {
      langs: ["en", "es", "jp", "kr", "vi", "ru"],
      currentLang: "en"
    };
  },

  methods: {
    selectLangHandler(e) {
      this.currentLang = e;
      i18n.locale = e;
      this.$store.commit("SET_language", this.currentLang);
      this.$router.push(`/${i18n.locale}`);
    }
  },
  mounted() {
    if (this.currentLanguage != "") {
      this.currentLang = this.currentLanguage;
    }
  },
  computed: {
    ...mapState({
      currentLanguage: s => s.currentLanguage
    })
  }
};
</script>
<style scoped>
.select-lang {
  font-size: 16.4px;
  background: #eaeaeb;
  border: none;
}
</style>