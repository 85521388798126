import Vuex from 'vuex'
import Vue from 'vue'
import { strictEqual } from 'assert';
Vue.use(Vuex);
export const store = new Vuex.Store({
    state: {
        privateKey: "",
        publicKey: "",
        balanceList: [],
        responseTransactionPack: {},
        transactionPackData: {},
        transactionCompletedResponse: {},
        createTokenData: {},
        createSmartData: {},
        networkAlias: "",
        currentLanguage: "",

    },
    mutations: {
        SET_publicKey: (state, payload) => {
            state.publicKey = payload;
        },
        SET_privateKey: (state, payload) => {
            state.privateKey = payload;
        },
        SET_balanceList: (state, payload) => {
            state.balanceList = payload;
        },
        SET_responseTransactionPack: (state, payload) => {
            state.responseTransactionPack = payload;
        },
        SET_transactionPackData: (state, payload) => {
            state.transactionPackData = payload;
        },
        SET_transactionCompletedResponse: (state, payload) => {
            state.transactionPackData = payload;
        },
        SET_createTokenData: (state, payload) => {
            state.createTokenData = payload;
        },
        SET_createSmartData: (state, payload) => {
            state.createSmartData = payload;
        },
        SET_network: (state, payload) => {
            state.networkAlias = payload;
        },
        SET_createTokenFormData: (state, payload) => {
            state.createTokenFormData = payload;
        },
        SET_transactionFormData: (state, payload) => {
            state.transactionFormData = payload;
        },
        SET_backwardUrl: (state, payload) => {
            state.backwardUrl = payload;
        },
        SET_language: (state, payload) => {
            state.currentLanguage = payload;
        }
    }
});