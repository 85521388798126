<template>
  <div>
      <Navbar />
      <div class="wrap">
          <router-view/>
    </div>
    <footer class="wrap-footer">
        <div class="wrap-footer-row copyback">
            <div class="footer-copyright">
                <div class="footer-copy">
                    <span class="bottom-footer-text">©©© 2016-{{currentYear}}   <a href="https://credits.com" target="_blank" style="color:white">Credits.com</a>   All Rights Reserved.</span>
                </div>
            </div>
        </div>
    </footer>
  </div>
</template>

<script>
import Navbar from "@/components/app/navbar";
export default {
     name: "main-layout",
    components:{
        Navbar,
    },
    computed:{
     currentYear: function(){
       return new Date().getFullYear();
     }
  },
};
</script>
