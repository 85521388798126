<template>
  <header class="header">
    <div class="top-bar">
      <div class="content top-bar__inner">
        <NetworkSelector />
        <div class="top-bar__el">
          <a
            href="https://developers.credits.com/en/Articles/a_How_to_use_Web_Wallet"
            target="_blank"
            class="el__top-menu-el font-weight-500"
          >FAQ</a>
          <a
            href="https://developers.credits.com/en/"
            target="_blank"
            class="el__top-menu-el font-weight-500"
          >Support</a>
          <!-- <div class="langBox">
                        <div>
                            <img src="/Content/image/Flags/flag.EN.png" alt="">
                            <div>
                                <div>EN</div>
                                <div class="lang">
                                    <div><a href="../ES">ES</a></div>
                                    <div><a href="../JP">JP</a></div>
                                    <div><a href="../KR">KR</a></div>
                                    <div><a href="../VI">VI</a></div>
                                    <div><a href="../RU">RU</a></div>
                                </div>
                            </div>
                            <div class="arrow-gray"></div>
                        </div>
          </div>-->
          <LangSwitcher />
        </div>
      </div>
    </div>
    <div class="content inner-wrap">
      <div onclick="document.location.href = '/'" class="logo-wrap" style="cursor:pointer">
        <a class="logo">
          <img src="/img/logo.png" alt="Logo Credits Wallet" />
        </a>
      </div>
      <div class="header-wrap">
        <nav class="menu">
          <div class="wrap_menu">
            <router-link :to="`/${$i18n.locale}/`" class="menu_item" active-class="active-link">Home</router-link>
            <a
              class="menu_item"
              target="_blank"
              href="https://developers.credits.com/en/Articles/a_Web_Wallet"
            >About</a>
            <a
              class="menu_item"
              target="_blank"
              href="https://github.com/CREDITSCOM/cs-wallet-web"
            >Github</a>
            <a class="menu_item" target="_blank" href="https://monitor.credits.com/Monitor">Monitor</a>
            <template v-if="privateKey!=''">
              <a class="menu_item" @click.prevent="logoutHandler">Logout</a>
            </template>
            <template v-else>
              <router-link
                class="menu_item"
                id="TopMenuRegister"
               :to="`/${$i18n.locale}/register`"
                active-class="active-link"
              >Register</router-link>
              <router-link
                class="menu_item"
                id="TopMenuLogin"
               :to="`/${$i18n.locale}/login`"
                active-class="active-link"
              >Login</router-link>
            </template>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>
            
          
<script>
import { mapState } from "vuex";
import NetworkSelector from "@/components/app/netSelect";
import LangSwitcher from "@/components/app/langSwitcher";
import i18n from "@/i18n";
export default {
  // name:"main-layout",

  computed: {
    ...mapState({
      privateKey: state => state.privateKey,
      publicKey: state => state.publicKey
    })
  },
  methods: {
    logoutHandler: function() {
      this.$store.commit("SET_privateKey", "");
      this.$store.commit("SET_publicKey", "");
     
      this.$router.push(`/${i18n.locale}`);
    }
  },
  components:{NetworkSelector,LangSwitcher}
};
</script>